import { IMedia } from '.';
import { IUser } from './user';
import { ICity } from './city';

interface IDevice {
    readonly id: number;
    model: IBaseModel;
    city?: ICity;
    osVersion?: IOperatingSystemVersion;
    owner?: IUser;
    createdAt?: IUser;
    serialNumber?: string;
    rivieraDeviceId: string;
    comment?: string;
    status?: DeviceStatus;
    imei?: string;
    udid?: string;
}

export type DeviceStatus = 'AVAILABLE' | 'DELETED' | 'NOT FOUND' | 'OUT OF OFFICE' | 'OUT OF ORDER' | 'OUT_OF_ORDER';
export const deviceStatus: Record<string, DeviceStatus> = {
    AVAILABLE: 'AVAILABLE',
    DELETED: 'DELETED',
    NOT_FOUND: 'NOT FOUND',
    OUT_OF_OFFICE: 'OUT OF OFFICE',
    OUT_OF_ORDER: 'OUT OF ORDER',
    OUTOFORDER: 'OUT_OF_ORDER',
} as const;

export default IDevice;

export interface IBrowser {
    readonly id: number;
    name: string;
    browserVersions?: IBrowserVersion[];
}

export interface IDeviceBrowser {
    readonly id: number;
    browser: IBrowser;
    browserVersion: IBrowserVersion;
}

export interface IBrowserVersion {
    readonly id: number;
    version: string;
    major: number;
    minor: number;
    browser: number;
    name?: null | string;
    configurations?: number;
    browserName: string;
    numericVersion: string;
}

export interface IOperatingSystem {
    readonly id: number;
    readonly name: string;
    readonly versions?: number;
    readonly devices?: number;
}

export interface IOperatingSystemVersion {
    readonly id: number;
    readonly name?: string;
    readonly version: string;
    readonly operatingSystem?: IOperatingSystem;
    readonly devices?: number;
    readonly isEditable?: boolean;
}

export interface IBaseModel {
    readonly id: number;
    readonly name: string;
    readonly manufacturer?: IManufacturer;
    readonly browsers?: IBrowser[];
    readonly defaultBrowser?: IBrowser;
    readonly screenResolution?: IResolution;
    readonly type?: ModelType;
    readonly operatingSystem?: IOperatingSystem;
    readonly screenSize?: string;
    readonly bluetoothVersion?: DevicePropertyValue;
    readonly bluetoothProfile?: DevicePropertyValue[];
    readonly wifiStandard?: DevicePropertyValue[];
    readonly verified?: boolean;
    readonly image?: IMedia;
    readonly alternateName?: string;
    readonly detectedModelName?: string;
    readonly releaseDate?: Date;
    readonly memoryCard?: boolean;
    readonly gps?: boolean;
    readonly nfc?: boolean;
    readonly wifi?: boolean;
    readonly scoreFr?: number;
    readonly scoreUs?: number;
    readonly simType?: DevicePropertyValue;
}

export interface DevicePropertyValue {
    readonly id: number;
    readonly value: string;
}

export type PropertyType = 'bluetoothversion' | 'manufacturer' | 'screenresolution';

export const modelTypes = ['computer', 'iot', 'mobile', 'smarttv', 'tablet', 'wearable'];
export type ModelType = (typeof modelTypes)[number];

export const memoryCardTypes = ['iot', 'mobile', 'tablet', 'computer'];
export const simNfcGpsTypes = ['iot', 'mobile', 'tablet', 'wearable'];
export const navigatorTypes = ['computer', 'mobile', 'smarttv', 'tablet'];
export const detectableTypes = ['computer', 'mobile', 'tablet'];
export const scoredTypes = ['mobile', 'tablet'];

export type IManufacturer = DevicePropertyValue;
export type IResolution = DevicePropertyValue;
export type ISimType = DevicePropertyValue;
