import { colors, createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material';
import { Theme, ThemeOptions } from '.';
import { deepmerge } from '@mui/utils';
// eslint-disable-next-line no-restricted-imports
import { Direction, THEMES } from '../settings';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface ThemeConfig {
    direction?: Direction;
    responsiveFontSizes?: boolean;
    theme?: string;
}

export const userRatings: Record<string, string> = {
    beginner: '#e73d3e',
    advanced: '#ff9800',
    intermediate: '#9c28af',
    expert: '#00aef3',
    master: '#37d0a4',
};

const baseOptions: ThemeOptions = {
    direction: 'ltr',
    typography,
    components: {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 32,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },
    },
    size: {
        drawer: '280px',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
};

const themesOptions = [
    {
        name: THEMES.BUGTRAPP,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 1,
                            color: 'rgba(0, 0, 0, 0.6)',
                        },
                    },
                },
            },
        },
        palette: {
            mode: 'light',
            action: {
                active: 'rgba(0, 0, 0, 0.54)',
                focus: 'rgba(0, 0, 0, 0.12)',
                hover: 'rgba(0, 0, 0, 0.04)',
                selected: 'rgba(0, 0, 0, 0.08)',
                disabled: 'rgba(0, 0, 0, 0.26)',
                disabledBackground: 'rgba(0, 0, 0, 0.12)',
            },
            background: {
                dark: '#F4F6F8',
                default: colors.common.white,
                paper: colors.common.white,
            },
            primary: {
                main: '#3C7ECA',
                light: '#75ADFE',
                dark: '#005299',
                contrastText: colors.common.white,
            },
            secondary: {
                main: '#CA883C',
                light: '#FFB86A',
                dark: '#955B0B',
                contrastText: colors.common.white,
            },
            error: {
                main: '#F44336',
                light: '#E57373',
                dark: '#D32F2F',
            },
            warning: {
                main: '#FF9800',
                light: '#FFB74D',
                dark: '#F57C00',
            },
            info: {
                main: '#2196F3',
                light: '#64B5F6',
                dark: '#1976D2',
            },
            success: {
                main: '#4CAF50',
                light: '#81C784',
                dark: '#388E3C',
            },
            text: {
                primary: '#253858',
                secondary: 'rgba(66, 82, 110, 0.86)',
                disabled: 'rgba(0, 0, 0, 0.38)',
            },
            divider: 'rgba(0, 0, 0, 0.12)',
        },
        shadows: softShadows,
    },
    {
        name: THEMES.WEARETESTERS,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 1,
                            color: 'rgba(0, 0, 0, 0.6)',
                        },
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#00AEF3',
                light: '#75ADFE',
                dark: '#007FC0',
                contrastText: colors.common.white,
            },
            secondary: {
                main: '#E73D3E',
                light: '#FF7369',
                dark: '#AD0017',
                contrastText: colors.common.white,
            },
            error: {
                main: '#F44336',
                light: '#F88078',
                dark: '#E31B0C',
            },
            warning: {
                main: '#FF9800',
                light: '#FFB547',
                dark: '#F57C00',
            },
            info: {
                main: '#2196F3',
                light: '#64B5F',
                dark: '#007FC0',
            },
            success: {
                main: '#4CAF50',
                light: '#7BC67E',
                dark: '#7BC67E',
            },
            text: {
                primary: 'rgba(37, 56, 88, 1)',
                secondary: 'rgba(66, 82, 110, 0.86)',
                disabled: 'rgba(0, 0, 0, 0.38)',
            },
            background: {
                dark: '#F4F6F8',
                default: '#FFF',
                paper: '#FFF',
            },
            divider: 'rgba(0, 0, 0, 0.12)',
        },
        shadows: softShadows,
    },
    {
        name: THEMES.LIGHT,
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 1,
                            color: colors.blueGrey[600],
                        },
                    },
                },
            },
        },
        palette: {
            mode: 'light',
            action: {
                active: colors.blueGrey[600],
            },
            background: {
                default: colors.common.white,
                dark: '#f4f6f8',
                paper: colors.common.white,
            },
            primary: {
                main: colors.indigo[600],
            },
            secondary: {
                main: '#5850EC',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows,
    },
    {
        name: THEMES.ONE_DARK,
        palette: {
            mode: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#282C34',
                dark: '#1c2025',
                paper: '#282C34',
            },
            primary: {
                main: '#8a85ff',
            },
            secondary: {
                main: '#8a85ff',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
        },
        shadows: strongShadows,
    },
    {
        name: THEMES.UNICORN,
        palette: {
            mode: 'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            background: {
                default: '#2a2d3d',
                dark: '#222431',
                paper: '#2a2d3d',
            },
            primary: {
                main: '#a67dff',
            },
            secondary: {
                main: '#a67dff',
            },
            text: {
                primary: '#f6f5f8',
                secondary: '#9699a4',
            },
        },
        shadows: strongShadows,
    },
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
    let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        [themeOptions] = themesOptions;
    }

    let theme = createMuiTheme(baseOptions, deepmerge(themeOptions, { direction: config.direction }));

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};
